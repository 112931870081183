const slots = [
  {
    type: 'Slot',
    moneyNode: false,
    cascade: false,
  }, {
    type: 'CashPot',
    moneyNode: false,
    cascade: false,
  }, {
    type: 'Cascade',
    moneyNode: true,
    cascade: true,
  }, {
    type: 'CascadeMultiplier',
    moneyNode: true,
    cascade: true,
  }, {
    type: 'CollectPots',
    moneyNode: true,
    cascade: false,
  },
];

/* eslint-disable arrow-body-style */
export const isSlot = (gameType) => {
  return slots.some((slot) => slot.type === gameType);
};

export const isNewSlot = (gameType) => {
  return gameType !== 'Slot' && slots.some((slot) => slot.type === gameType);
};

export const isCascadeSlot = (gameType) => {
  return slots.some((slot) => slot.type === gameType && slot.cascade);
};

export const isMoneyNodeSlot = (gameType) => {
  return slots.some((slot) => slot.type === gameType && slot.moneyNode);
};

export const groupToSlot = (gameType) => {
  return slots.some((slot) => slot.type === gameType) ? 'Slot' : gameType;
};
